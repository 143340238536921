/* ***************** MODULE PAGES ***************** */

/****************** General ************************/
/***************************************************/
/* dates */
#events_module.cms_list .cms_date,
#feeds_module.cms_list .cms_list_item .cms_date h4,
#feeds_module.cms_entity .cms_date h4,
#news_module.cms_list article time,
#news_module.cms_entity .cms_date h3,
#photoalbums_module.cms_list .cms_list_item .cms_number_of_images, /* this is not a date but styled as one */
.posts-node .posts-field.posts-field-posted p,
#video_module.cms_list .cms_list_item .cms_date,
#video_module.cms_entity .cms_date h3 {
	font-size: 12px;
	line-height: 18px;
	font-weight: 700;
	color: #474747;
	font-family: Arial, sans-serif;
}

/* ui form tags */
.ui-form-container {
	border: 0px;
}

.ui-form-container .ui-form-legend {
	background-color: transparent;
	margin-top: 10px;
	margin-bottom: 5px;
	color: #bf3028;
	font-size: 12px;
	line-height: 18px;
	font-weight: 700;
	text-transform: none;
	padding: 0px;
	border-bottom: 0px;
}

.ui-form-container .ui-form-fields {
	border-bottom: 0px;
}

.ui-form-container .ui-form-fields .ui-form-field.required .ui-form-label em,
.ui-form-container .required_information em {
	color: #ff0000;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices {
	border: 1px solid #cccccc;
	border-radius: 0px;
	padding: 5px 7px;
	font-size: 12px;
	line-height: 1.42857143;
	color: #474747;
	box-shadow: none;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
	padding: 0px;
}

.ui-form-container .ui-form-fields .select2-container .select2-choice {
	box-shadow: none;
	border-radius: 0px;
	background: none;
	border: 1px solid #cccccc;
}

.ui-form-buttons {
	padding: 0px;
	width: 100%;
	text-align: center;
}

@media (max-width: 991px) {
	.ui-form-container .ui-form-fields {
		padding: 0px;
	}

	.ui-form-container .ui-form-label {
		position: static;
		padding: 0px;
		height: auto;
		display: block;
		width: 100%;
		left: 0px;
		text-align: left;
		top: auto;
		line-height: inherit;
	}

	.ui-form-container .ui-form-input {
		width: 100%;
	}

	.ui-form-container .ui-form-input input[type="text"],
	.ui-form-container .ui-form-input input[type="tel"],
	.ui-form-container .ui-form-input input[type="password"],
	.ui-form-container .ui-form-input input[type="email"],
	.ui-form-container .ui-form-input input[type="file"],
	.ui-form-container .ui-form-input select,
	.ui-form-container .ui-form-input textarea,
	.ui-form-container .ui-form-fields .select2-container,
	.ui-form-container .ui-form-fields .select2-container-multi,
	.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
		width: calc(100% - 16px) !important;
		max-width: 100%;
	}
}

@media (max-width: 479px) {
	.ui-form-buttons button {
		margin-bottom: 10px;
	}
}

/****************** Accounts ***********************/
/***************************************************/
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 240px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_buttons {
	margin-top: 0;
}

#account_module.form .form_container input[type="password"] {
	margin-bottom: 10px;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid #cccccc;
	margin: 20px 0;
	padding-bottom: 7px;
	color: #bf3028;
	font-size: 17px;
	font-weight: bold;
	line-height: 18px;
	font-family: Arial, sans-serif;
}

.form .form_item .label,
.form .form_item.required .label {
	background-color: transparent;
}

/* edit address form */
#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #ff0000;
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url("/core/public/shared/assets/images/required.gif");
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

/****************** Events *********************/
/***********************************************/
#fullcalendar-controls #categories .category-wrapper .category {
	top: 0px;
}

#events_module.cms_list .cms_list_item .cms_title {
	width: 100%;
}

#events_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#events_module.cms_list .cms_list_item .event-category-label {
	display: inline-block;
	margin-right: 4px;
}

#events_module.cms_form .cms_label,
#events_module.cms_form .cms_field,
#events_module.cms_form .cms_required,
#events_module.cms_form .cms_non_required,
#events_module.cms_form .cms_td_answers {
	background-color: transparent;
	vertical-align: top;
}

#events_module.cms_form .cms_required {
	background-position: center 10px;
}

@media (max-width: 991px) {
	#events_module.cms_form .cms_field input[type="text"],
	#events_module.cms_form .cms_field input[type="password"],
	#events_module.cms_form .cms_field select,
	#events_module.cms_form .cms_field textarea,
	#events_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: calc(100% - 14px) !important;
		max-width: 100%;
	}

	#events_module.cms_form .cms_label {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
	}

	#events_module.cms_form .cms_required,
	#events_module.cms_form .cms_non_required {
		width: 20px;
		display: inline;
		text-align: left;
		padding-left: 0px;
		float: left;
	}
}

/****************** FAQs *********************/
/*********************************************/
#faqs_module .cms_list_item > ul {
	padding-left: 15px;
	margin-top: 0;
}

#faqs_module .cms_title h3 {
	margin-top: 0px;
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 7px;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
}

#faqs_module.cms_list .cms_list_item .description,
#faqs_module.cms_list .cms_list_subitem .description {
	padding-top: 0;
}

/****************** News ***********************/
/***********************************************/
#news_module.cms_entity .cms_date h3 {
	margin-top: 0px;
}

#news_module.cms_list .cms_categories_row .image {
	margin-bottom: 10px;
}

.news_module.cms_category_list .cms_category_header_image {
	margin-bottom: 10px;
}

.box.archive .archive_links {
	overflow: hidden;
}

@media (max-width: 767px) {
	#news_module.cms_entity .cms_content .image {
		display: block;
		float: none;
		margin-left: 0px;
		height: auto;
		max-width: 100%;
	}
}

/****************** Photo Albums ****************/
/***********************************************/
#photoalbums_module.cms_list .cms_list_item table td.cms_image {
	padding-right: 15px;
}

#photoalbums_module.cms_list .cms_list_item table td.cms_image a img {
	max-width: 150px;
}

@media (max-width: 991px) {
	#photoalbums_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#photoalbums_module.cms_list .cms_list_item table td.cms_image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/******************* Posts *********************/
/***********************************************/
.posts-collection .posts-node .posts-field h2 {
	margin-top: 0px;
}

.posts-node #post-comments .post-comments-title {
	background-color: #727939;
}

.posts-node #post-comments .post-comment .post-comment-author {
	font-size: 0.8rem;
}

.posts-node #post-comments .post-comment {
	margin-top: 15px;
	padding: 0px 0px 15px 0px;
	border-bottom: 1px solid #d6d6d6;
}

.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-textarea textarea,
.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-input input[type="text"] {
	width: calc(100% - 16px) !important;
}

#posts_module.form .required_message span {
	background-image: none;
}

#posts_module.form .form_item .ui-form-label,
#posts_module.form .form_item .required .ui-form-label {
	width: 42%;
	text-align: right;
}

.posts-node .posts-comments #post-add-comment .ui-form-fields {
	margin-bottom: 0px;
}

#posts_module.form .ui-form-fieldset {
	padding-bottom: 0;
}

#posts_module.form .ui-form-panel {
	padding-bottom: 0 !important;
}

#posts_module.form .ui-form-buttons {
	margin-top: 0;
}

#posts_module.form .ui-form-buttons .ui-form-field {
	padding: 0;
}

@media (max-width: 1077px) {
	#posts_module.form .form_item .ui-form-label,
	#posts_module.form .form_item .required .ui-form-label {
		width: 38%;
	}
}

@media (max-width: 991px) {
	#posts_module.form .ui-form-fieldset,
	#posts_module.form .ui-form-panel {
		padding: 0 !important;
		margin-left: 0;
	}

	#posts_module.form .form_item .ui-form-label,
	#posts_module.form .form_item .required .ui-form-label {
		width: 100%;
		text-align: left;
	}

	#posts_module.form .ui-form-buttons {
		text-align: left;
	}

	#posts_module.form .ui-form-container .ui-form-input input[type="text"] {
		max-width: 348px;
	}

	#posts_module.form .ui-form-fields,
	#posts_module.form .ui-form-field {
		overflow: hidden;
	}
}

/* sidebar posts */
.posts-blocks.posts-blocks-searches .posts-blocks-search-form .posts-blocks-search-button {
	padding: 11px 12px 7px !important;
	max-height: 42px;
	min-width: unset;
	height: 42px;
}

.posts-blocks.posts-blocks-subscribes .posts-blocks-subscribe span {
	float: none;
	width: auto;
}

.inside-page-nested-right-column .box.posts-blocks.posts-blocks-subscribes .posts-blocks-subscribe a,
.inside-page-nested-right-column .box.posts-blocks.posts-blocks-rss-feeds .posts-blocks-rss-feed a {
	border-bottom: 0px;
}

.inside-page-nested-right-column .box.posts-blocks.posts-blocks-authors .posts-blocks-posts,
.inside-page-nested-right-column .box.posts-blocks.posts-blocks-authors .posts-blocks-author,
.inside-page-nested-right-column .box.posts-blocks.posts-blocks-categories .posts-blocks-category {
	padding-bottom: 7px;
}

.inside-page-nested-right-column .box.posts-blocks.posts-blocks-authors .posts-blocks-posts {
	padding-left: 7px;
}

.inside-page-nested-right-column .posts-blocks.posts-blocks-searches .posts-blocks-search-form input[type="text"].posts-blocks-search-field {
	height: 41px;
}

/****************** Products *******************/
/***********************************************/
#products_module.responsive .items .item-wrapper .item .item-image {
	padding: 0;
}

.category-header-image-wrap img {
	width: 100%;
}

.fly-out-menu-container .fly-out-menu-button {
	border: 1px solid #cccccc;
}

#products_module.responsive.cms_form .preferred-contact-method-label.ui-form-label {
	font-weight: 400;
}

#products_module.responsive.cms_form .ui-form-field fieldset {
	padding: 0;
}

#products_module.responsive.cms_form .ui-form-container .ui-form-fields .ui-form-field.required .ui-form-label em {
	padding-left: 5px;
}

#products_module.responsive.cms_form .ui-form-note {
	line-height: 14px;
}

#products_module.responsive.cms_entity .product-details-info-wrapper .product-details-info {
	padding: 15px;
}

#products_module.responsive .product-prices .item-price {
	font-size: 1rem;
	font-weight: 700;
}

#products_module.responsive .add_to_cart_form .quantity_row input {
	padding-left: 7px;
}

#products_module.responsive .add_to_cart_form .subtotal_row strong {
	color: #474747;
}

.product-attribute.custom-options-attribute fieldset {
	padding: 0;
	margin: 0;
}

#products_module.responsive .add_to_cart_form .attribute-type-date img {
	vertical-align: sub;
}

#products_module.responsive .attributeSelectionWithImages .attributeSelectDescription,
#products_module.responsive .attributeSelectionWithImages .attributeCaption {
	display: inline;
}

.contentbox_item select {
	height: auto;
}

#products_module.responsive .availability_box .unavailable,
#products_module.responsive .availability_box .available {
	color: #474747;
	font-weight: 700;
}

#products_module.responsive .add_to_cart_form .submit_row_center {
	margin-top: 15px;
}

#products_module.responsive .add_to_cart_form .attributeSelectionLabel {
	display: block;
}

#products_module.responsive .add_to_cart_form .cart-estimate-button {
	margin-right: 5px;
}

#s-cart #s-promotions .s-form-container input {
	height: auto;
}

#s-cart #s-promotions .s-form-container button {
	height: 28px;
}

#s-cart .s-row .s-quantity-field,
#s-wishlist .s-row .s-quantity-field,
#s-favorites .s-row .s-quantity-field {
	height: auto;
}

.ui-form-label em {
	color: #ff0000;
}

#s-customer #s-sign-in .ui-form-label {
	height: auto;
}

#s-customer .s-button {
	line-height: 15px;
}

h2.add-a-new-address-header {
	border-top-color: #d6d6d6;
}

@media (max-width: 991px) {
	#ffIsRecommended {
		width: 100% !important;
	}
}

@media (max-width: 767px) {
	#products_module.responsive .items.list-view .item-wrapper .item .item-image,
	#products_module.responsive .items.list-view .item-wrapper .item .item-description {
		float: none;
		margin-left: 0;
	}

	#products_module.responsive .items.list-view .item-wrapper .item .item-description {
		width: 100%;
	}
}

/***************** Resources *******************/
/***********************************************/

/***************** RSS Feeds *******************/
/***********************************************/
#feeds_module.cms_list .cms_title h3,
#feeds_module.cms_list .cms_date h4 {
	margin-top: 0px;
}

#feeds_module.cms_list .abstract {
	padding: 0px;
}

/*************** Submission Forms ***************/
/************************************************/
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding: 4px 27px;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 5px 7px;
}

#submissionforms_module.cms_form .ui-datepicker-trigger {
	left: 5px;
	top: 5px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 11px;
	color: inherit;
}

#submissionforms_module.cms_form .cms_field input[size="60"],
#submissionforms_module.cms_form .cms_field input.hasDatepicker[size="60"][type="text"],
#submissionforms_module.cms_form .cms_field textarea[cols="60"] {
	width: calc(100% - 16px);
}

#submissionforms_module.cms_form .cms_buttons .cms_form_button {
	display: inline-block;
	min-width: 100px;
	margin-top: 16px;
	margin-right: 16px;
	margin-bottom: 16px;
	padding: 0.8rem 1rem 0.7rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	border-radius: 0px;
	background-color: #bf3028;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	line-height: 1rem;
	font-weight: 600;
	text-align: center;
	letter-spacing: 2px;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
}

#submissionforms_module.cms_form .cms_buttons .cms_form_button:hover {
	letter-spacing: 3px;
	background-color: #bf3028;
}

#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary {
	border-style: solid;
	border-width: 2px;
	border-color: #7c843e;
	background-color: #fff;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #7c843e;
	text-align: center;
	cursor: pointer;
}


#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover {
	border-color: #7c843e;
	background-color: #fff;
	background-image: none;
	opacity: 1;
	color: #7c843e;
}

/* form with payment */
#submissionforms_module.cms_form .cms_field.currency::before,
.responsive #submissionforms_module.cms_form .cms_field.currency::before {
	top: 27%;
}

#submissionforms_module.cms_form #form-payment-info .ui-form-field-subtitle .ui-form-label label,
#submissionforms_module.cms_form #form-payment-info .ui-form-field-subtitle .ui-form-label span {
	font-size: 100%;
	font-weight: 400;
}

#submissionforms_module.cms_form #form-payment-info .s-payment-method-label {
	display: inline;
}

#submissionforms_module.cms_form #form-payment-info #payment-info .ui-form-panel {
	padding-top: 0 !important;
}

#submissionforms_module.cms_form #form-payment-info .ui-form-fieldset:not(#payment-info) .ui-form-panel {
	padding-bottom: 0 !important;
}

#submissionforms_module.cms_form #form-payment-info .ui-form-expiration-date-fields > div:first-of-type {
	margin-bottom: 10px;
}

#submissionforms_module.cms_form #form-payment-info .ui-form-text-phone-extension {
	margin-top: 10px;
	margin-left: 0;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

/* confirmation page */
.module-forms.confirmation .step-title {
	margin-top: 10px;
	color: #bf3028;
	font-size: 17px;
	line-height: 18px;
	font-weight: 700;
}

.module-forms.confirmation .value-cell img.signature-image {
	max-width: 100%;
}

@media (max-width: 991px) {
	.module-forms.confirmation td {
		display: block;
		width: 100%;
	}

	.module-forms.confirmation .label-cell {
		padding-bottom: 0;
	}

	.module-forms.confirmation .value-cell {
		padding-top: 0;
	}
}

@media (max-width: 767px) {
	#submissionforms_module.cms_form .cms_field.currency::before,
	.responsive #submissionforms_module.cms_form .cms_field.currency::before {
		top: 35%;
		left: 5px;
	}
}

/**************** Testimonials ******************/
/***********************************************/
#testimonials_module.cms_list .cms_list_item .cms_bottom,
#testimonials_module.cms_list .cms_list_item .cms_content {
	display: inline-block;
	width: 100%;
}

#testimonials_module.cms_list .cms_list_item .cms_content .testimonial-link {
	border-bottom: 0px;
}

/******************* Videos ********************/
/***********************************************/
#video_module.cms_list .cms_list_item {
	border-bottom: 1px solid #d6d6d6;
	padding-bottom: 15px;
	margin-bottom: 15px;
}


/* ***************** DYNAMIC CONTENT BOXES ***************** */
/******************* Events ********************/
/***********************************************/
#events_module.homepage_contentbox {
	padding: 40px 20px 10px;
}

#events_module.homepage_contentbox > br {
	display: none;
}

#events_module.homepage_contentbox .event_list {
	margin-bottom: 10px;
}

#events_module.homepage_contentbox .footer {
	text-align: right;
}

#events_module.homepage_contentbox .footer a {
	text-transform: capitalize;
}

#events_module.homepage_contentbox .cms_description {
	color: #474747;
	font-size: 12px;
	line-height: 18px;
}

/******************** News *********************/
/***********************************************/
#news_module.homepage_contentbox {
	padding: 40px 20px 10px;
	overflow: hidden;
}

#news_module.homepage_contentbox .cms_list_item {
	margin-bottom: 10px;
}

#news_module.homepage_contentbox .cms_title h3 {
	margin-top: 0;
	margin-bottom: 3px;
}

#news_module.homepage_contentbox .cms_date h3 {
	font-size: 11px;
	font-weight: normal;
	text-transform: uppercase;
	margin-top: 0;
	margin-bottom: 3px;
}

#news_module.homepage_contentbox > a {
	float: right;
}

/******************** Posts ********************/
/***********************************************/
.cb .collection.posts-collection {
	padding: 40px 20px 10px;
}

.cb .collection.posts-collection > .posts-node {
	margin-top: 0;
	padding-top: 0;
}

.cb .collection.posts-collection .field.posts-field:first-of-type p {
	color: #59842e;
	font-size: 15px;
	font-weight: normal;
	line-height: 18px;
	margin-bottom: 3px;
}

.cb .collection.posts-collection .field.posts-field:last-of-type p {
	text-align: right;
}

.posts-collection.homepage-contentbox.article-items {
	margin-bottom: 0;
	padding-bottom: 0;
}

.posts-collection.homepage-contentbox.article-items .article {
	padding-left: 0;
	padding-right: 0;
}

.posts-collection.homepage-contentbox.article-items .article-date {
	font-size: 11px;
	font-weight: normal;
	text-transform: uppercase;
	margin-top: 0;
	margin-bottom: 5px;
	color: #59842e;
}

.cb .posts-collection.homepage-contentbox.article-items p.article-abstract {
	padding: 0;
	margin: 0;
}

.posts-homepage-block.button.primary {
	background: transparent none;
	float: right;
	padding-bottom: 10px !important;
}

.posts-homepage-block.button.primary:hover {
	background: transparent none !important;
}

@media (max-width: 991px) {
	.posts-collection.homepage-contentbox.article-items .article-content,
	.posts-collection.homepage-contentbox.article-items .article-image-link {
		float: none;
		width: 100%;
		padding-left: 0;
	}

	.posts-collection.homepage-contentbox.article-items .article-image-link {
		margin-bottom: 10px;
	}
}

@media (max-width: 767px) {
	.posts-collection.homepage-contentbox.article-items .article {
		max-width: none;
	}
}

/****************** Products *******************/
/***********************************************/
#products_module.featured_list,
#products_module.homepage_contentbox {
	padding: 40px 20px 10px;
	overflow: hidden;
}

#products_module.homepage_contentbox .metadata1 {
	margin: 0 0 3px;
}

.cb #products_module.homepage_contentbox .cms_content p {
	line-height: 18px;
}

.cb #products_module.homepage_contentbox .cms_content > a {
	float: right;
	text-transform: capitalize;
}

#products_module.homepage_contentbox.displaymode_category_list td {
	display: block;
	width: 100%;
}

#products_module.homepage_contentbox.displaymode_category_list td img {
	max-width: 100px;
}

/****************** RSS Feeds ******************/
/***********************************************/
#feeds_module.homepage_contentbox {
	padding: 40px 20px 10px;
	overflow: hidden;
}

#feeds_module.homepage_contentbox > a {
	float: right;
}

/************** Submission forms ***************/
/***********************************************/
#submissionforms_module.homepage_contentbox {
	padding: 40px 20px 10px;
}

#submissionforms_module.homepage_contentbox .cms_divider {
	display: none;
}

#submissionforms_module.homepage_contentbox label {
	display: inline;
}

#submissionforms_module.homepage_contentbox .ui-datepicker-trigger {
	vertical-align: baseline;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
}

#submissionforms_module.homepage_contentbox .cms_field input[type="text"],
#submissionforms_module.homepage_contentbox .cms_field input[type="tel"],
#submissionforms_module.homepage_contentbox .cms_field input[type="password"],
#submissionforms_module.homepage_contentbox .cms_field input[type="email"],
#submissionforms_module.homepage_contentbox .cms_field input[type="date"],
#submissionforms_module.homepage_contentbox .cms_field input.hasDatepicker[type="text"],
#submissionforms_module.homepage_contentbox .cms_field select,
#submissionforms_module.homepage_contentbox .cms_field textarea {
	width: calc(100% - 16px);
}

@media (max-width: 767px) {
	#submissionforms_module.homepage_contentbox tr[data-required="true"] .cms_label label::after,
	#submissionforms_module.homepage_contentbox tr[data-required="true"] .cms_label div::after {
		content: "";
		padding: 0;
	}
}

/**************** Testimonials *****************/
/***********************************************/
#testimonials_module_contentbox {
	padding: 40px 20px 10px;
}

#testimonials_module_contentbox.contentbox_item.withThumbnail {
	width: calc(100% - 40px);
}

#testimonials_module_contentbox .image-box {
	margin-bottom: 10px;
}

#testimonials_module_contentbox .author-name,
#testimonials_module_contentbox .company {
	font-weight: 700;
}

/******************** Videos *******************/
/***********************************************/
.homepage-featured-video {
	position: fixed;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	height: 750px;
}

.homepage-featured-video video {
	width: 100%;
	height: auto;
	z-index: -100;
}

.homepage-featured-video .cbTitle {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: 35%;
	font-family: Homestead, sans-serif;
	font-size: 4rem;
	width: 100%;
	text-align: center;
}

.homepage-featured-video {
	position: relative;
}

.homepage-featured-video .cbTitle:after {
	background-image: url("/assets/images/white_leaf.svg");
	content: ' ';
	background-repeat: no-repeat;
	position: absolute;
	width: 75px;
	height: 80px;
	transform: translateX(-50%);
	left: 50%;
	top: 50px;
}

.homepage-featured-video iframe {
	width: 100% !important;
}

.homepage-featured-video .moreVideos {
	text-align: right;
	margin-top: 3px;
	margin-bottom: 3px;
	margin-right: 7px;
	display: none;
}

.homepage-featured-video .moreVideos a {
	text-transform: capitalize;
}

@media (max-width: 991px) {
	.homepage-featured-video .cbTitle {
		top: 25%;
		font-size: 3rem;
	}

	.homepage-featured-video .cbTitle:after {
		width: 55px;
		height: 50px;
	}
}

@media (max-width: 767px) {
	.homepage-featured-video .cbTitle {
		top: 18%;
		font-size: 2rem;
	}

	.homepage-featured-video .cbTitle:after {
		width: 35px;
		top: 35px;
	}
}

@media (max-width: 479px) {
	.homepage-featured-video video {
		width: 462px;
		height: 260px;
	}

	.homepage-featured-video .cbTitle {
		font-size: 1.6rem;
	}
}
