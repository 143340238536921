.main-content a,
.text-link {
	border-bottom: 1px solid #bf3028;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #696b6d;
	font-weight: 500;
	text-decoration: none;
	cursor: pointer;
}

.main-content a:hover,
.text-link:hover {
	border-bottom-style: solid;
	border-bottom-color: transparent;
}

img {
	height: auto;
	max-width: 100%;
	vertical-align: middle;
	display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6,
.heading-6,
.heading-7,
.heading-8,
.heading-9,
.heading-10,
.heading-11,
.heading-12,
.heading-13,
.heading-14 {
	color: #bf3028;
}

.main-content h1 a,
.main-content h2 a,
.main-content h3 a,
.main-content h4 a,
.main-content h5 a,
.main-content h6 a,
.heading-link {
	border-bottom: 0 !important;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #727939;
	text-decoration: none;
	cursor: pointer;
	font-weight: inherit;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
.heading-link:hover {
	border-bottom-color: transparent;
	color: rgba(124, 132, 62, 0.7);
}

.primary,
.secondary,
.tertiary,
a.primary,
a.secondary,
a.tertiary,
.button-4 {
	display: inline-block;
	min-width: 100px;
	margin-top: 16px;
	margin-right: 16px;
	margin-bottom: 16px;
	padding: 0.8rem 1rem 0.7rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	border-radius: 0px;
	background-color: #bf3028;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	line-height: 1rem;
	font-weight: 600;
	text-align: center;
	letter-spacing: 2px;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
}

.primary:hover,
.secondary:hover,
.tertiary:hover,
a.primary:hover,
a.secondary:hover,
a.tertiary:hover,
.button-4:hover {
	letter-spacing: 3px;
}

.secondary,
a.secondary,
.button-4.secondary {
	border-style: solid;
	border-width: 2px;
	border-color: #727939;
	background-color: #fff;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #727939;
	text-align: center;
	cursor: pointer;
}

.secondary:hover,
a.secondary:hover,
.button-4.secondary:hover {
	border-color: #727939;
	background-color: #fff;
	background-image: none;
	opacity: 1;
	color: #727939;
}

.tertiary,
a.tertiary,
.button-4.tertiary {
	min-width: 60px;
	margin-top: 0.5rem;
	margin-right: 0rem;
	margin-bottom: 0.5rem;
	padding: 0.25rem 0.75rem;
	border-style: solid;
	border-width: 2px;
	border-color: #bf3028;
	background-color: transparent;
	color: #bf3028;
	font-size: 0.9rem;
	line-height: 1.4rem;
}

.tertiary:hover,
a.tertiary:hover,
.button-4.tertiary:hover {
	background-color: #fff;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	color: #bf3028;
	border-color: #bf3028;
}

blockquote,
.block-quote {
	margin-bottom: 1rem;
	padding: 1rem 1.5rem;
	border-left-color: #7c843e;
	background-color: #bf3028;
	color: #fff;
	font-size: 1.35rem;
	line-height: 2rem;
}

.main-content blockquote a {
	color: #fff;
	border-bottom-color: #fff;
}

blockquote > *:last-of-type {
	margin-bottom: 0;
}

#session-message-wrapper .success {
	background-color: #adebb0;
	text-align: left;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	color: #333;
	font-size: 1.125rem;
	line-height: 1.5rem;
	font-weight: 400;
}

#session-message-wrapper .error {
	background-color: #f5dadd;
	text-align: left;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	color: #333;
	font-size: 1.125rem;
	line-height: 1.5rem;
	font-weight: 400;
}

#session-message-wrapper .success p,
#session-message-wrapper .success ul li,
#session-message-wrapper .error p,
#session-message-wrapper .error ul li {
	margin-top: 0;
	font-weight: 400;
	font-size: 1.125rem;
	color: #333;
}

.success :last-child,
.error :last-child {
	margin-bottom: 0;
}

/*forms*/
.cms_label,
.label,
label {
	font-weight: 400;
}

label {
	display: inline;
	margin-bottom: 0;
}

input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"],
input[type="search"],
textarea,
select {
	font-size: 0.875rem;
	line-height: 1.42857143;
	padding: 8px 12px;
	color: #333;
	border: 1px solid #ccc;
}

textarea {
	resize: vertical;
}

/* end of forms */

/* Styled tables */
/* Overall Table Characteristics */
.styled {
	width: 100%;
	margin: 0 0 0.75rem 0;
	background-color: white;
	border-spacing: 0px;
}

/* Vertical Cell Borders */

.styled th,
.styled td {
	border-right: 1px solid #2e2e2e;
}

.styled tr th:last-child,
.styled tr td:last-child {
	border-right: none;
}

/* Table Header - aka Table Title */

.styled thead tr th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #6f3724;
	color: white;
}

/* Optional Column Headers */

.styled th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #905844;
	color: #ffffff;
}

/* This code controls any visual distinctions for link decoration */

.styled th a {
	border-bottom: 0;
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
}

/* Depending on your design, you may want this for the link hover state */

.styled th a:hover {
	color: inherit;
	font-weight: inherit;
	text-decoration: none;
}

/* Overall Data Characteristics */

.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
}

/* Even Row Data */

.striped.styled tr:nth-child(even) {
	background: #dce0ae;
}

/* Odd Row Data */

.striped.styled tr:nth-child(odd) {
	background: transparent;
}

/* end of Styled tables */

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
}


/* Webflow */
#printHeader,
#printFooter {
	display: none;
}

.navbar {
	position: fixed;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	z-index: 301;
	height: auto;
	margin-top: -10px;
	background-color: transparent;
}

.navbar.sticky {
	z-index: 0;
}

.nav-link {
	margin-left: 0px;
	padding-right: 10px;
	padding-left: 25px;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 0px;
	text-transform: uppercase;
}

.nav-link.active-item {
	color: #fff;
	background-image: url("/20200110134700/assets/images/white_leaf.svg");
	background-position: 0% 50%;
	background-size: 20px;
	background-repeat: no-repeat;
}

.open .nav-link,
.nav-link:hover {
	padding-left: 25px;
	border-bottom: 1px none #000;
	background-image: url('/20200110134700/assets/images/white_leaf.svg');
	background-position: 0% 50%;
	background-size: 20px;
	background-repeat: no-repeat;
	color: #fff;
}

.drop-down-link.w-nav-link .active-item,
.drop-down-link.w-nav-link a:hover {
	border-bottom: 1px none #000;
	background-image: url("/20200110134700/assets/images/white_leaf.svg");
	background-position: 0% 50%;
	background-size: 20px;
	background-repeat: no-repeat;
	color: #fff;
}

.logo-div {
	max-width: none;
	margin-right: 0px;
	margin-left: 40px;
	padding-right: 0px;
}

.brand {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	height: auto;
	margin-top: -30px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.outdoor-logo {
	width: 180px;
	max-width: none;
	margin-top: 0px;
	margin-right: 0px;
	margin-left: 0px;
	border-radius: 100%;
	background-color: #fff;
}

.video-section {
	position: relative;
	height: auto;
}

.background-video {
	position: fixed;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	height: 750px;
}

.scratch-no-more {
	width: 600px;
}

.video-caption {
	position: relative;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	height: auto;
	margin-top: 190px;
	margin-bottom: auto;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.white-leaf {
	width: 80px;
	margin-top: -19px;
}

.what-is-rhustox {
	position: relative;
	height: auto;
	margin-top: 620px;
	background-color: #fff;
}

.mc-leaf-border {
	position: static;
	width: auto;
	margin-top: -146px;
}

.what-is-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: -123px;
	margin-bottom: 0px;
	padding-right: 0px;
	padding-bottom: 100px;
	padding-left: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.mc-text {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 40%;
	height: auto;
	margin-right: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.heading-2 {
	font-family: Homestead, sans-serif;
	color: #bf3028;
	font-size: 45px;
	text-align: center;
	letter-spacing: 3px;
	width: 100%;
}

.main-paragraph {
	width: 100%;
}

.main-paragraph p {
	margin-top: 20px;
	margin-right: 0px;
	margin-left: 0px;
	padding-top: 0px;
	padding-right: 0px;
	padding-left: 0px;
	font-family: 'Open Sans', sans-serif;
	font-size: 16px;
	line-height: 1.5;
	font-weight: 400;
	text-align: center;
}

.main-paragraph a,
.buy-now {
	margin-top: 10px;
	margin-right: auto;
	margin-left: auto;
	padding: 15px 40px;
	border-style: none;
	border-width: 2px;
	border-color: #727939;
	background-color: #727939;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 15px;
	font-weight: 700;
	letter-spacing: 3px;
	text-transform: uppercase;
	text-decoration: none;
}

.main-paragraph a:hover,
.buy-now:hover {
	background-color: #7c843e;
	letter-spacing: 6px;
}

.main-paragraph p:last-of-type {
	margin-top: 30px;
}

.nav-menu {
	width: auto;
	max-width: 90%;
	margin-top: 10px;
	margin-right: 0px;
	padding: 0px 10px;
	background-color: #bf3028;
}

.content-boxes {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 10px;
	padding-bottom: 160px;
	padding-left: 10px;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
}

.content-box {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: auto;
	height: 450px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
	background-image: url('/20200110134700/assets/images/poision-ivy.jpg');
	background-position: 0px 0px;
	background-size: auto 100%;
	background-repeat: no-repeat;
	text-decoration: none;
}

.footer-section {
	position: relative;
	z-index: 1;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	background-color: #7c843c;
}

.green-leaf-border {
	width: 2570px;
	max-width: none;
	margin-top: 0px;
}

.leaf-div {
	position: relative;
	overflow: hidden;
	max-width: 100%;
	height: 275px;
	background-color: #fff;
	z-index: 0;
}

.div-block-11 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: none;
	margin-right: 0px;
	padding-top: 10px;
	padding-right: 20px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.list-link-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 33.33%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #fff;
}

.list-link-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-left: -0.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.phone-number {
	display: none;
	color: #dbdbdb;
	text-decoration: none;
}

.address {
	color: #fff;
	font-size: 17px;
	line-height: 22px;
	text-align: center;
}

.footer-list-link {
	margin-bottom: 0.35rem;
	padding-right: 0.25rem;
	padding-bottom: 0rem;
	padding-left: 0.35rem;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-bottom: 1px none hsla(0, 0%, 54.5%, 0.25);
	border-left: 3px solid transparent;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #fff;
	font-size: 17px;
	line-height: 1.3rem;
	text-align: center;
	text-decoration: none;
}

.footer-list-link:hover {
	border-bottom-color: transparent;
	border-left-color: transparent;
	color: #6f3724;
}

.link-list-title {
	margin-top: 0px;
	margin-bottom: 5px;
	color: #fff;
	font-size: 22px;
	line-height: 26px;
	font-weight: 500;
	text-align: center;
}

.footer-links-div {
	position: relative;
	padding: 0rem 2rem;
	background-color: #727939;
}

.link-list-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 85%;
	min-width: 925px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 0.25rem;
	padding-left: 0.25rem;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.footer-logo {
	border-radius: 100%;
	background-color: #fff;
}

.footer-signoff-link-wrap {
	display: inline-block;
}

.footer-signoff-links {
	display: inline-block;
	margin-right: 1rem;
	border-bottom: 2px solid rgba(111, 55, 36, 0.53);
	color: #fff;
	line-height: 1.25rem;
}

.footer-signoff-links:hover {
	border-bottom-color: transparent;
}

.signoff-div {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 20px;
	padding-bottom: 20px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #727939;
}

.footer-signoff-link {
	-webkit-transition: color 300ms ease;
	transition: color 300ms ease;
	color: #fff;
	font-size: 0.8rem;
	letter-spacing: 1px;
	text-decoration: none;
}

.footer-signoff-link:hover {
	color: #6f3724;
}

.column-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 0px;
	padding-left: 0px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.column-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 0px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.footer-copyright {
	display: inline-block;
	margin-right: 1.5rem;
	margin-bottom: 0rem;
	color: #fff;
	font-size: 0.85rem;
}

.footer-copyright h1 {
	display: inline-block;
	margin-right: 1.5rem;
	margin-bottom: 0rem;
	color: #fff;
	font-size: 0.85rem;
	line-height: 20px;
	font-family: Arial, sans-serif;
	margin: 0;
	font-weight: normal;
}

div#GRIPFooterLogo:hover {
	opacity: 1;
}

div#GRIPFooterLogo {
	opacity: 0.8;
	transition: all 350ms ease;
}

#GRIPFooterLogoText {
	line-height: 1;
}

.sign-off-div-wrap {
	width: 85%;
	height: 52px;
	min-width: auto;
	padding-right: 2rem;
	padding-left: 2rem;
}

.testimonial {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 700px;
	margin-top: 0px;
	margin-bottom: 0px;
	padding-top: 100px;
	padding-bottom: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
	background-image: url('/20200110134700/assets/images/testimonial.svg');
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
}

.happy-camper {
	margin-top: 0px;
	font-family: Homestead, sans-serif;
	color: #fff;
	font-size: 35px;
	letter-spacing: 3px;
}

#testimonials_module_contentbox,
.testimonial-paragraph {
	max-width: 600px;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 15px;
	text-align: center;
}

#testimonials_module_contentbox .author-name,
#testimonials_module_contentbox .company,
.name {
	max-width: 600px;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 20px;
	font-weight: 700;
}

.cb-heading {
	width: 100%;
	margin-top: auto;
	margin-bottom: 0px;
	margin-left: 0px;
	padding: 10px 10px 5px;
	background-color: transparent;
	-webkit-transform: translate3d(0px, 0px, 300px);
	transform: translate3d(0px, 0px, 300px);
	font-family: Homestead, sans-serif;
	color: #fff;
	font-size: 30px;
	line-height: 30px;
	text-align: center;
	letter-spacing: 3px;
	text-decoration: none;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.order-button {
	margin-top: 10px;
	background-color: #fff;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #bf3028;
	font-size: 14px;
	font-weight: 800;
	letter-spacing: 2px;
	text-transform: uppercase;
}

.order-button:hover {
	letter-spacing: 3px;
}

.cb-paragraph-2,
.cb-paragraph-3,
.cb-paragraph {
	width: 100%;
}

.cb-paragraph p {
	margin-right: 20px;
	margin-bottom: 0px;
	margin-left: 20px;
	opacity: 0;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	text-align: center;
}

.cb-leaf {
	width: 50px;
	margin-top: 10px;
	margin-bottom: auto;
	opacity: 0;
}

.cb-red-leaves {
	width: 800px;
	max-width: none;
	margin-top: 0px;
}

.body {
	overflow: visible;
}

.content-box-1 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: auto;
	height: 450px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
	background-image: url('/20200110134700/assets/images/AdobeStock_273703719_Preview.jpeg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	text-decoration: none;
}

.cb-caption {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 80px;
	margin-top: -34px;
	padding-top: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #bf3028;
}

.cb-caption-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 80px;
	margin-top: -34px;
	padding-top: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #bf3028;
}

.cb-caption-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 80px;
	margin-top: -34px;
	padding-top: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #bf3028;
}

.cb-paragraph-2 p {
	margin-right: 20px;
	margin-bottom: 0px;
	margin-left: 20px;
	opacity: 0;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	text-align: center;
}

.cb-paragraph-3 p {
	margin-right: 20px;
	margin-bottom: 0px;
	margin-left: 20px;
	opacity: 0;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	text-align: center;
}

.icon {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.footer-grip-link {
	width: 133px;
	height: 29px;
	min-width: 0px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-left: 33px;
	background-image: url('/20200110134700/assets/images/globalreach_dark.svg');
	background-position: 50% 50%;
	background-size: 130px 29px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	text-decoration: none;
	cursor: pointer;
}

.footer-grip-link.white {
	width: 135px;
	background-image: url('/20200110134700/assets/images/globalreach_white.svg');
	background-size: 150px 29px;
}

.grip-logo-text-box {
	color: #000;
	font-size: 0.5rem;
	line-height: 0.5rem;
	font-weight: 700;
	letter-spacing: 0.01rem;
	text-decoration: none;
	text-transform: uppercase;
}

.grip-logo-text-box.webdevby-white {
	color: #fff;
}

.product {
	width: 30%;
	margin-right: 60px;
}

.link-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 0px;
	padding-bottom: 70px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	text-decoration: none;
}

.link-block-2 {
	width: 30%;
	text-decoration: none;
}

.link-block-3 {
	width: 30%;
	text-decoration: none;
}

.link-block-4 {
	width: 30%;
	text-decoration: none;
}

.red-leaf-container {
	width: 100%;
	height: 160px;
	margin-bottom: -24px;
}

.dropdown-toggle {
	padding: 0;
	height: 0;
	opacity: 0;
	overflow: hidden;
	display: block;
}

.dropdown-list {
	padding-left: 10px;
	padding-right: 10px;
	display: block;
	list-style-type: none;
	background-color: #bf3028;
	display: flex;
	flex-direction: column;
	color: #bf3028;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.dropdown-list.w--open {
	width: 150px;
	padding-right: 10px;
	background-color: #bf3028;
	color: #bf3028;
}

.drop-down-link {
	width: 100%;
	margin-left: 0px;
	padding: 0;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 14px;
	line-height: 18px;
	font-weight: 600;
	letter-spacing: 0px;
	text-transform: none;
}

.drop-down-link:hover {
	border-bottom: 1px none #000;
	background-image: url('/20200110134700/assets/images/white_leaf.svg');
	background-position: 0% 50%;
	background-size: 20px;
	background-repeat: no-repeat;
	color: #fff;
}

.drop-down-link.w--current {
	background-image: url('/20200110134700/assets/images/white_leaf.svg');
	background-position: 0% 50%;
	background-size: 20px;
	background-repeat: no-repeat;
	color: #fff;
}

.navbar-2 {
	display: none;
}

.background-image {
	position: fixed;
	z-index: -10;
	width: 100%;
	height: 500px;
	margin-top: -320px;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.36)), to(rgba(0, 0, 0, 0.36))), url('/20161223162900/assets/images/banner.jpg');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.36), rgba(0, 0, 0, 0.36)), url('/20161223162900/assets/images/banner.jpg');
	background-position: 0px 0px, 0px 0px;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.image-21 {
	margin-top: 0px;
	height: 400px;
	max-width: none;
	position: relative;
	z-index: 300;
}

.div-block-20 {
	overflow: hidden;
	width: 100%;
	margin-top: 265px;
}

.section-2 {
	position: relative;
	z-index: 300;
	height: auto;
	margin-top: -179px;
	padding-bottom: 0px;
	background-color: #fff;
}

.left-nav-section-title {
	margin-top: 0px;
	border-bottom: 1px solid #fff;
	margin-bottom: 0.625rem;
}

.breadcrumb-divider {
	display: inline-block;
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	color: #424242;
	font-size: 0.5rem;
}

.intro-paragraph {
	margin-bottom: 1.5rem;
	color: #424242;
	font-size: 1.75rem;
	line-height: 2.25rem;
}

.main-content-outer-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.left-nav-link-3 {
	display: block;
	width: auto;
	margin-bottom: 0.5rem;
	padding: 2px 0rem 2px 1.3rem;
	border-left: 2px none transparent;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	-webkit-transition: all 0ms ease;
	transition: all 0ms ease;
	color: #fff;
	font-size: 1rem;
	line-height: 1.2rem;
	font-weight: 600;
	text-decoration: none;
	text-transform: uppercase;
}

.left-nav-link-3:hover {
	width: auto;
	padding-right: 0rem;
	padding-left: 1.3rem;
	border-left-style: none;
	border-left-color: #c5c5c5;
	background-image: url('/20200110134700/assets/images/white_leaf.svg');
	background-position: 0% 50%;
	background-size: 17px;
	background-repeat: no-repeat;
	color: #fff;
}

.left-nav-link-3.selected,
.left-nav-link-3.w--current {
	border-left-color: #c2d82e;
	background-color: hsla(0, 0%, 100%, 0.13);
	background-image: url('/20200110134700/assets/images/green_leaf.svg');
	background-position: 0% 50%;
	background-size: 17px;
	background-repeat: no-repeat;
	-webkit-transition-duration: 450ms;
	transition-duration: 450ms;
	color: #fff;
}

.left-nav-link-3.w--current:hover {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	font-weight: 600;
}

.left-nav-link-3.grandchild-link {
	padding-top: 1px;
	padding-bottom: 1px;
	border-left-style: none;
	border-left-color: transparent;
	-webkit-transition-duration: 0ms;
	transition-duration: 0ms;
	font-size: 0.9rem;
	line-height: 1.25rem;
	font-weight: 600;
	text-transform: none;
}

.left-nav-link-3.grandchild-link:hover {
	border-left-color: #c5c5c5;
}

.left-nav-link-3.grandchild-link.selected,
.left-nav-link-3.grandchild-link.w--current {
	border-left-color: #c2d82e;
	background-image: url('/20200110134700/assets/images/green_leaf.svg');
	background-position: 0% 50%;
	background-size: 17px;
	font-weight: 600;
}

.left-nav-link-3.greatgrandchild-link {
	padding-top: 1px;
	padding-bottom: 1px;
	border-left-style: none;
	border-left-color: transparent;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	-webkit-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: 450ms;
	transition-duration: 450ms;
	font-size: 0.8rem;
	line-height: 1.13rem;
	font-weight: 400;
	text-transform: none;
}

.left-nav-link-3.greatgrandchild-link:hover {
	border-left-style: none;
	border-left-color: #c5c5c5;
	background-image: url('/20200110134700/assets/images/white_leaf.svg');
	background-position: 0% 50%;
	background-size: 15px;
	background-repeat: no-repeat;
	box-shadow: none;
	color: #fff;
}

.left-nav-link-3.greatgrandchild-link.selected,
.left-nav-link-3.greatgrandchild-link.w--current {
	border-left-color: #c2d82e;
	background-image: url('/20200110134700/assets/images/green_leaf.svg');
	background-position: 0% 50%;
	background-size: 15px;
	background-repeat: no-repeat;
	-webkit-transition-duration: 450ms;
	transition-duration: 450ms;
	color: #fff;
	font-size: 0.83rem;
	line-height: 1.13rem;
	font-weight: 700;
}

.left-nav-wrapper {
	position: relative;
	margin-top: -71px;
	margin-right: 1rem;
	margin-left: 0rem;
	padding-right: 0.75em;
	padding-left: 0.75em;
}

.left-nav-ul {
	display: block;
	margin-bottom: 0.5rem;
	background-color: transparent;
}

.breadcrumbs-list {
	margin-bottom: 2rem;
	padding-left: 0px;
	background-color: transparent;
	font-family: 'Open Sans', sans-serif;
}

.breadcrumbs-list-item {
	display: inline-block;
	float: left;
}

.breadcrumb-link {
	border-bottom: 1px solid #7c843e !important;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #000;
	font-size: 0.8rem;
	line-height: 1.5rem;
	font-weight: 400;
	text-decoration: none;
	cursor: pointer;
}

.breadcrumb-link:hover {
	border-bottom-color: transparent !important;
	color: #696b6d !important;
}

.breadcrumb-link.current-bc-link {
	border-bottom-style: none !important;
	color: #bf3028;
	font-weight: 700;
}

.breadcrumb-link.current-bc-link:hover {
	color: #bf3028 !important;
}

.main-content-inner-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 0rem;
	color: #333;
	width: 100%;
}


.left-nav-section-title-link {
	display: block;
	margin-top: 11px;
	padding-top: 0.25rem;
	padding-right: 0px;
	padding-bottom: 0.4rem;
	color: #fff;
	font-size: 1.25rem;
	line-height: 1.4rem;
	font-weight: 600;
	text-decoration: none;
	text-transform: uppercase;
}

.horiz-divider {
	height: 1px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	background-color: rgba(0, 0, 0, 0.15);
}

.table {
	background-color: transparent;
	color: #333;
}

.breadcrumbs-wrapper {
	display: block;
	color: #424242;
	font-size: 0.8rem;
	line-height: 1.5rem;
	font-weight: 400;
	text-decoration: none;
}

.ordered-list {
	padding-left: 20px;
}

.left-nav-col {
	width: 20%;
	height: 100%;
	max-width: 250px;
	min-height: 300px;
	min-width: 200px;
	margin-top: 45px;
	padding: 1rem 0px 4rem 0em;
	float: left;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-right: 1px solid rgba(0, 0, 0, 0.15);
	background-color: #bf3028;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
}

.container-2 {
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
}

.container-2.inside-page-container {
	max-width: calc(1200px + 4rem);
	padding: 0 2rem;
}

.left-nav-li.grandchild-li {
	margin-left: 0.75rem;
}

.left-nav-li.greatgrandchild-li {
	margin-top: 2px;
	margin-left: 0.85rem;
	font-size: 0.9rem;
}

.content-outer-wrap-2 {
	position: relative;
	z-index: 2;
	display: block;
	margin-right: auto;
	border-top: 8px none #629edc;
}

.image-22 {
	width: 600px;
	max-width: none;
	margin-top: 0px;
}

.div-block-21 {
	overflow: hidden;
	width: 100.5%;
	margin-top: -74px;
}

.image-23 {
	width: 25px;
}

.social-icon {
	margin-right: 20px;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.social-icon.facebook {
	margin-right: 6px;
}

.social-icon.twitter {
	margin-right: 13px;
}

.social-icon:hover {
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
}

.social-icon:last-of-type {
	margin-right: 0;
}

.div-block-22 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin: 0px 0px 20px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.right-side-col {
	position: relative;
	width: 25%;
	max-width: 250px;
	min-width: 160px;
	margin-top: 0px;
	padding-left: 10px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	background-color: transparent;
	font-family: 'Open Sans', sans-serif;
}

.right-col-item-wrap {
	padding-bottom: 0.4rem;
	border-bottom: 1px solid rgba(124, 132, 62, 0.5);
}

.list-item-3 {
	margin-bottom: 10px;
}

.h2-right-column-title {
	margin-top: 0px;
	margin-left: -10px;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 10px;
	background-color: transparent;
	color: #bf3028;
	font-size: 18px;
	line-height: 24px;
	font-weight: 700;
}

.h4-right-column-title {
	margin-left: -10px;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 10px;
	background-color: transparent;
	color: #bf3028;
}

.h3-right-column-title {
	margin-left: -10px;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 10px;
	background-color: transparent;
	color: #bf3028;
	font-size: 18px;
	line-height: 24px;
	font-weight: 700;
}

.right-side-col a,
.right-column-link {
	padding-right: 10px;
	padding-left: 10px;
	border-bottom: 1px none #e41356;
	background-color: transparent;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #727939;
	text-decoration: underline;
	display: inline-block;
}

.right-side-col a:hover,
.right-column-link:hover {
	border-bottom: 1px none transparent;
	background-color: rgba(124, 132, 62, 0.13);
	color: #7c843e;
	text-decoration: none;
}

.link {
	color: #fff;
	text-decoration: none;
}

#testimonials_module_contentbox {
	padding: 0;
}

#testimonials_module_contentbox .description-box {
	margin-bottom: 10px;
}

/* Accessible menu */
.main-nav-ul {
	padding: 0;
	margin: 0;
	list-style-type: none;
	display: flex;
}

.open .dropdown-toggle {
	height: auto;
	opacity: 1;
	display: block;
	overflow: visible;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.drop-down-link.w-nav-link a {
	color: #fff;
	text-decoration: none;
	display: inline-block;
	width: 100%;
	padding: 10px 10px 10px 25px;
}

.main-nav-li:last-of-type .dropdown-toggle .dropdown-list {
	right: -10px;
}

/* end of homepage */

/*mmenu*/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened ~ .mm-slideout {
		-webkit-transform: translate(0, 0);
		-ms-transform: translate(0, 0);
		transform: translate(0, 0);
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@media (max-width: 991px) {
	#ecommerceLinks {
		top: 20px !important;
	}

	#ecommerceLinks a {
		padding: 8px !important;
	}

	.mm-menu {
		background: #fff;
	}

	.navigation-menu {
		top: 0;
		margin-top: 0;
		width: 80%;
	}

	.mobile-navigation-menu,
	.desktop-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
		background-color: #bf3028;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened ~ .mm-slideout {
		-webkit-transform: translate(80%, 0);
		-ms-transform: translate(80%, 0);
		transform: translate(80%, 0);
		-webkit-transform: translate3d(80%, 0, 0);
		transform: translate3d(80%, 0, 0);
	}

	.mm-menu_offcanvas {
		max-width: none;
	}

	.mm-panel {
		padding: 0;
	}

	.mm-panels > .mm-panel > .mm-listview {
		margin: -20px 0 20px;
	}

	.mm-panels > .mm-panel + .mm-panel > .mm-listview {
		margin-top: 41px;
	}

	.mm-menu li {
		display: block !important;
	}

	.mm-menu a,
	.mm-menu a:active,
	.mm-menu a:link,
	.mm-menu a:visited {
		color: #fff;
		padding: 1.25em 2.1875rem;
		float: none;
		box-shadow: none;
		border-bottom: 1px solid #0003;
		text-transform: uppercase;
	}

	.mm-menu .mobile-menu-item.active > a:not(.mm-btn_next),
	.mm-menu .mobile-menu-item.active > span,
	.mm-menu .active > a:not(.mm-btn_next),
	.mm-menu .active > span,
	.mm-menu .mm-listitem_selected > a:not(.mm-btn_next),
	.mm-menu .mm-listitem_selected > span {
		background-color: transparent;
		cursor: pointer;
	}

	.mm-menu a.w--current {
		background-color: transparent;
		color: #fff;
		cursor: pointer;
	}

	.mm-menu a:hover {
		background-color: transparent;
		color: #fff;
		cursor: pointer;
	}

	li.mm-listitem:hover {
		background-image: url("/20191219153700/assets/images/white_leaf.svg");
		background-position: 1.5% 50%;
		background-size: 20px;
		background-repeat: no-repeat;
	}

	li.mm-listitem.active,
	li.mm-listitem.mm-listitem_selected {
		background-image: url("/20191219153700/assets/images/white_leaf.svg");
		background-position: 1.5% 50%;
		background-size: 20px;
		background-repeat: no-repeat;
	}

	.mm-listitem:after {
		border-bottom-width: 0px;
	}

	.mm-menu .mm-btn_prev:hover,
	.mm-menu .mm-navbar__title:hover,
	.mm-menu .mm-btn_next:hover {
		color: #fff;
		background-color: transparent;
		cursor: pointer;
	}

	.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) {
		width: 20%;
	}

	.mm-listitem .mm-btn_next:before {
		border-left-width: 1px;
		border-left-color: #0003;
	}

	.mm-menu .mm-btn:after,
	.mm-menu .mm-btn:before,
	.mm-menu .mm-listview .mm-btn_next:after {
		border-color: #fff;
		-webkit-transition: border-color 300ms;
		transition: border-color 300ms;
	}

	.mm-menu .mm-btn:hover:after,
	.mm-menu .mm-btn:hover:before,
	.mm-menu .mm-listview .mm-btn_next:hover:after {
		border-color: #fff;
	}

	.mm-btn_next:after,
	.mm-btn_prev:before {
		width: 9px;
		height: 9px;
	}

	.mm-btn_next:after {
		right: 44%;
	}

	.mm-btn_prev:before {
		margin-left: 0;
	}

	.mm-menu .mm-listview .mm-btn_next:hover {
		background-color: transparent;
		color: #fff;
		cursor: pointer;
	}

	.mm-menu .mm-listview .mm-btn_next + a {
		width: 80%;
		margin-right: 20%;
	}

	.mm-navbar {
		border-bottom: 1px solid #0003;
		padding: 1px 10px 0 40px;
		height: 61px;
		margin-right: 0;
	}

	.mm-menu .mm-navbar a,
	.mm-menu .mm-navbar > * {
		transition: color 300ms;
		height: 61px;
		border-bottom: 0 none !important;
	}

	.mm-menu a.mm-navbar__title {
		font-size: 1rem;
		line-height: 1.25rem;
		font-weight: 400;
		text-align: left;
		padding-left: 8px !important;
		text-transform: capitalize;
		border-bottom: 0 none;
	}

	.mm-navbar:hover {
		cursor: pointer;
		background-color: transparent;
	}

	.mm-wrapper_opened #printHeader + .navbar {
		position: relative;
	}

	.mm-wrapper_opened .div-block-20 {
		margin-top: 100px;
	}

	.mm-wrapper_opened .image-21 {
		margin-top: 0;
	}

	.mm-wrapper_opened .background-image {
		margin-top: -166px;
	}
}

@media (max-width: 767px) {
	.mm-wrapper_opened .div-block-20 {
		margin-top: 150px;
	}

	.mm-wrapper_opened .background-image {
		margin-top: -117px;
	}
}

@media (max-width: 479px) {
	.mm-wrapper_opened .div-block-20 {
		margin-top: 165px;
	}

	.mm-wrapper_opened .background-image {
		margin-top: -99px;
	}
}

/* end of mmenu */

/* inner page styling */
.main-content {
	display: flex;
	width: 100%;
	padding-top: 1rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	font-family: 'Open Sans', sans-serif;
}

.main-content.both-sidebars {
	width: 80%;
	display: flex;
	padding-left: 3vw;
}

.main-content.with-left-sidebar {
	padding-left: 3vw;
	width: 80%;
}

.main-content.with-right-sidebar .main-content-inner {
	width: 80%;
	padding-right: 3vw;
}

.main-content-inner {
	width: 100%;
}

.main-content.both-sidebars .main-content-inner {
	width: 75%;
	padding-right: 3vw;
}

@media (max-width: 991px) {

	.main-content.both-sidebars,
	.main-content.with-left-sidebar {
		padding-left: 0;
		width: 100%;
	}

	.main-content.both-sidebars .main-content-inner,
	.main-content.with-right-sidebar .main-content-inner {
		width: 100%;
		padding-right: 0;
	}
}

/* end of inner page styling */

.responsive-iframe {
	position: relative;
	overflow: hidden;
	padding-top: 56.25%;
}

.responsive-iframe iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
}

@media (max-width: 1300px) {
	.leaf-div {
		height: 230px;
	}

	.green-leaf-border {
		width: 2000px;
	}
}

@media (max-width: 1090px) {
	.nav-link {
		padding-top: 15px;
		padding-bottom: 15px;
		font-size: 13px;
	}

	.logo-div {
		margin-left: 30px;
	}

	.outdoor-logo {
		width: 140px;
	}
}

@media (max-width: 991px) {
	.navbar {
		position: fixed;
		left: 0%;
		top: 0%;
		right: 0%;
		bottom: auto;
		z-index: 0;
	}

	.nav-link {
		margin-left: 0px;
	}

	.logo-div {
		margin-right: 20px;
		margin-left: 20px;
	}

	.brand {
		margin-top: -41px;
	}

	.outdoor-logo {
		width: 150px;
	}

	.background-video {
		height: 500px;
	}

	.scratch-no-more {
		width: 500px;
	}

	.what-is-rhustox {
		z-index: 300;
		background-color: transparent;
	}

	.mc-leaf-border {
		width: 138%;
		max-width: none;
		margin-top: 109px;
	}

	.what-is-container {
		margin-top: -103px;
		margin-bottom: 0px;
		background-color: #fff;
	}

	.mc-text {
		height: auto;
	}

	.heading-2 {
		font-size: 40px;
	}

	.main-paragraph p {
		margin-top: 0px;
		margin-right: 0px;
		margin-left: 0px;
		line-height: 25px;
	}

	.main-paragraph a,
	.buy-now {
		font-size: 15px;
	}

	.nav-menu {
		position: fixed;
		left: 0%;
		top: 0%;
		right: auto;
		bottom: 0%;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 80%;
		height: 100%;
		margin-top: 0px;
		padding-right: 10px;
	}

	.content-boxes {
		margin-bottom: 0px;
		padding-right: 0px;
		padding-bottom: 60px;
		padding-left: 0px;
		z-index: 300;
	}

	.content-box {
		width: auto;
		height: 350px;
		background-position: 50% 50%;
		background-size: cover;
	}

	.footer-section {
		position: relative;
	}

	.div-block-11 {
		position: static;
		z-index: 300;
		margin-top: 10px;
		margin-right: 0px;
		padding-right: 20px;
	}

	.list-link-div {
		width: 33.33%;
		margin-bottom: 1rem;
	}

	.footer-list-link {
		text-align: center;
	}

	.footer-links-div {
		margin-top: -117px;
	}

	.link-list-wrapper {
		width: 100%;
		min-width: auto;
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.footer-signoff-link-wrap {
		display: block;
		margin-bottom: 1.5rem;
		float: left;
	}

	.footer-signoff-links {
		margin-right: 0.75rem;
		margin-left: 0rem;
	}

	.signoff-div {
		padding-bottom: 1.5rem;
	}

	.column-3 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.column-2 {
		padding-right: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
	}

	.footer-copyright {
		margin-right: 0rem;
		margin-bottom: 0.25rem;
		float: left;
	}

	.sign-off-div-wrap {
		width: 100%;
		min-width: auto;
	}

	.testimonial {
		height: 550px;
		margin: 0px;
		padding-top: 6px;
		z-index: 300;
	}

	.happy-camper {
		margin-top: 15px;
		margin-bottom: 0px;
		font-size: 30px;
	}

	.cb-heading {
		margin-top: auto;
		margin-bottom: 0px;
		padding-top: 15px;
		padding-bottom: 0px;
		font-size: 25px;
		line-height: 25px;
	}

	.order-button {
		margin-right: 20px;
	}

	.cb-paragraph p {
		margin-right: 10px;
		margin-left: 10px;
	}

	.cb-red-leaves {
		margin-top: 0px;
		width: 700px;
	}

	.content-box-1 {
		width: auto;
		height: 350px;
	}

	.cb-caption {
		margin-top: -25px;
	}

	.cb-caption-2 {
		margin-top: -25px;
	}

	.cb-caption-3 {
		margin-top: -25px;
	}

	.cb-paragraph-2 p {
		margin-right: 10px;
		margin-left: 10px;
	}

	.cb-paragraph-3 p {
		margin-right: 10px;
		margin-left: 10px;
	}

	.menu-button {
		position: relative;
		left: auto;
		top: 36%;
		right: 0%;
		bottom: 0%;
		z-index: 500;
		margin-top: 7px;
		margin-right: 20px;
		color: #bf3028;
	}

	.menu-button.w--open {
		background-color: transparent;
	}

	.icon {
		-webkit-transform: translate3d(0px, 0px, 300px);
		transform: translate3d(0px, 0px, 300px);
		color: #fff;
		font-size: 41px;
	}

	.product {
		width: 40%;
	}

	.white-leaf-container {
		overflow: hidden;
		margin-top: -383px;
	}

	.link-block {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-bottom: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-decoration: none;
	}

	.drop-down-link {
		margin-left: 0px;
	}

	.navbar-2 {
		position: fixed;
		left: 0%;
		top: 0%;
		right: 0%;
		bottom: auto;
		z-index: 300;
		margin-left: 200px;
		display: block;
		margin-top: 55px;
		background-color: transparent;
	}

	.div-block-20 {
		margin-top: 0;
	}

	.background-image {
		margin-top: 0;
	}

	.image-21 {
		height: 300px;
		margin-bottom: -150px;
		margin-top: 265px;
	}

	.section-2 {
		margin-top: 0;
	}

	.intro-paragraph {
		font-size: 1.65rem;
		line-height: 2.35rem;
	}

	.main-content-outer-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.breadcrumb-link {
		margin-top: 0.25rem;
	}

	.main-content {
		width: 100%;
		padding-top: 0rem;
		flex-direction: column;
	}

	.main-content-inner-wrap {
		display: block;
		padding-top: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.breadcrumbs-wrapper {
		margin-top: 0.5rem;
		font-size: 0.9rem;
		line-height: 1.5rem;
	}

	.left-nav-col {
		display: none;
	}

	.container-2 {
		max-width: 768px;
	}

	.content-outer-wrap-2 {
		height: 100%;
		padding-top: 0rem;
	}

	.right-side-col {
		width: 100%;
		max-width: none;
		min-width: 0px;
		margin-top: 2rem;
		margin-left: 0rem;
		padding-top: 0rem;
		border-top: 1px solid rgba(0, 0, 0, 0.15);
		padding-top: 20px;
	}

	.video-section {
		min-height: 620px;
		position: relative;
	}

	.what-is-rhustox {
		margin-top: 0;
	}

	.green-leaf-border {
		width: 1500px;
	}

	.leaf-div {
		height: 280px;
	}

	.red-leaf-container {
		height: 110px;
	}
}

@media (max-width: 767px) {
	.nav-link {
		width: 100%;
		margin-left: 0px;
	}

	.outdoor-logo {
		width: 100px;
		margin-top: 0px;
	}

	.background-video {
		height: 400px;
	}

	.scratch-no-more {
		width: 300px;
	}

	.video-caption {
		margin-top: 154px;
	}

	.white-leaf {
		width: 50px;
	}

	.mc-leaf-border {
		width: 125%;
		margin-top: 25px;
	}

	.what-is-container {
		margin-top: -41px;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.mc-text {
		width: 90%;
		margin-top: 42px;
	}

	.nav-menu {
		margin-top: 0px;
		padding-right: 10px;
	}

	.content-boxes {
		padding-bottom: 0px;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.content-box {
		width: auto;
		margin-bottom: 40px;
	}

	.green-leaf-border {
		width: 1500px;
	}

	.leaf-div {
		height: 310px;
		z-index: 999;
		background-color: #fff;
	}

	.testimonial + .leaf-div {
		background-color: transparent;
	}

	.list-link-div {
		width: 50%;
	}

	.address {
		margin-bottom: 20px;
	}

	.footer-list-link {
		text-align: center;
	}

	.footer-links-div {
		display: block;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
	}

	.link-list-wrapper {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.footer-signoff-link-wrap {
		display: inline-block;
		float: none;
	}

	.footer-signoff-links.last {
		margin-right: 0rem;
	}

	.column-3 {
		overflow: hidden;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.column-2 {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.footer-copyright {
		float: none;
	}

	.sign-off-div-wrap {
		height: auto;
	}

	.testimonial {
		height: auto;
		margin-right: 0px;
		margin-bottom: -204px;
		margin-left: 0px;
		padding-top: 40px;
		padding-bottom: 200px;
		background-color: #bf3028;
		background-image: none;
		background-size: auto;
		background-repeat: repeat;
	}

	.happy-camper {
		margin-top: 10px;
		margin-bottom: 5px;
		font-size: 25px;
		line-height: 30px;
		width: 100%;
		text-align: center;
	}

	#testimonials_module_contentbox,
	.testimonial-paragraph {
		width: 100%;
		max-width: 490px;
		margin-bottom: 5px;
		padding: 0 10px;
		font-size: 13px;
		line-height: 17px;
	}

	#testimonials_module_contentbox .author-name,
	#testimonials_module_contentbox .company,
	.name {
		font-size: 15px;
	}

	.content-box-1 {
		width: auto;
		margin-bottom: 40px;
	}

	.cb-caption {
		margin-top: -49px;
	}

	.cb-caption-2 {
		margin-top: -49px;
	}

	.cb-caption-3 {
		margin-top: -49px;
	}

	.menu-button.w--open {
		margin-top: 10px;
	}

	.product {
		width: 70%;
		margin-top: -5px;
		margin-right: 0px;
	}

	.white-leaf-container {
		margin-top: -427px;
	}

	.link-block-2 {
		width: 70%;
	}

	.link-block-3 {
		width: 70%;
	}

	.link-block-4 {
		width: 70%;
	}

	.drop-down-link {
		width: 100%;
		margin-left: 0px;
	}

	.section-2 {
		margin-top: -61px;
	}

	.main-content-outer-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.breadcrumbs-list {
		margin-bottom: 1.5rem;
	}

	.main-content-inner-wrap {
		padding-top: 0rem;
	}

	.breadcrumbs-wrapper {
		margin-top: 0.25rem;
		font-size: 0.85rem;
	}

	.container-2 {
		max-width: 550px;
	}

	.content-outer-wrap-2 {
		padding-bottom: 2rem;
	}

	.footer-section {
		background-color: #7c843c;
		padding-top: 0;
		z-index: 1;
	}

	.section-2 ~ .footer-section {
		background-color: #fff;
	}

	.red-leaf-container {
		height: 140px;
	}

	.image-21 {
		margin-bottom: -60px;
	}
}

@media (max-width: 479px) {
	.logo-div {
		margin-top: -12px;
	}

	.brand {
		margin-top: -85px;
	}

	.outdoor-logo {
		width: 80px;
		margin-top: 0px;
	}

	.video-section {
		overflow: hidden;
	}

	.background-video {
		height: 400px;
	}

	.scratch-no-more {
		width: 250px;
		margin-top: 33px;
	}

	.video-caption {
		margin-top: 146px;
	}

	.what-is-rhustox {
		margin-top: 57px;
		background-color: transparent;
	}

	.mc-leaf-border {
		width: 600px;
		margin-top: 45px;
	}

	.what-is-container {
		overflow: hidden;
		margin-top: -69px;
		padding-right: 10px;
		padding-left: 10px;
	}

	.mc-text {
		width: 100%;
		margin-top: 43px;
	}

	.nav-menu {
		width: 80%;
	}

	.content-box {
		width: 100%;
		margin-bottom: 0px;
	}

	.footer-section {
		position: relative;
		overflow: visible;
	}

	.green-leaf-border {
		width: 930px;
		margin-top: 44px;
	}

	.leaf-div {
		overflow: hidden;
		height: 225px;
	}

	.div-block-11 {
		padding-right: 0px;
	}

	.list-link-div {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		margin-bottom: 1.5rem;
		padding-bottom: 0rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-bottom: 1px none hsla(0, 0%, 54.5%, 0.25);
	}

	.list-link-wrapper {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.address {
		text-align: center;
	}

	.footer-list-link:hover {
		border-left-color: transparent;
	}

	.footer-links-div {
		margin-top: -55px;
	}

	.link-list-wrapper {
		margin-top: 26px;
	}

	.footer-signoff-links.last {
		margin-right: 0rem;
	}

	.column-3 {
		margin-bottom: 0.5rem;
	}

	.column-2 {
		padding-left: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.footer-copyright {
		margin-bottom: 0.5rem;
		text-align: center;
	}

	.testimonial {
		height: auto;
		margin-top: 0px;
		padding: 40px 10px 200px;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		border-top: 9px solid #7c843e;
		background-color: #bf3028;
		background-image: none;
		background-size: auto;
		background-repeat: repeat;
	}

	#ecommerceLinks {
		right: 175px !important;
		top: 74px !important;
	}

	.order-button {
		margin-top: 64px;
		margin-right: 10px;
		padding-right: 10px;
		padding-left: 10px;
		font-size: 11px;
		letter-spacing: 1px;
	}

	.order-button:hover {
		letter-spacing: 2px;
	}

	.body {
		overflow: visible;
	}

	.content-box-1 {
		width: 100%;
		margin-bottom: 0px;
	}

	.cb-caption {
		margin-top: -75px;
	}

	.cb-caption-2 {
		margin-top: -75px;
	}

	.cb-caption-3 {
		margin-top: -75px;
	}

	.icon {
		color: #fff;
		font-size: 34px;
	}

	.product {
		width: 90%;
		margin-top: 32px;
	}

	.white-leaf-container {
		overflow: hidden;
		margin-top: -506px;
	}

	.link-block-2 {
		width: 100%;
	}

	.link-block-3 {
		width: 100%;
	}

	.link-block-4 {
		width: 100%;
	}

	.red-leaf-container {
		margin-bottom: 2px;
	}

	.navbar-2 {
		margin-top: 0px;
	}

	.section-2 {
		margin-top: -32px;
	}

	.intro-paragraph {
		font-size: 1.45rem;
		line-height: 2.15rem;
	}

	.main-content {
		padding-top: 0rem;
	}

	.table {
		max-width: 100%;
	}

	.breadcrumbs-wrapper {
		margin-top: 0rem;
	}

	.container-2 {
		max-width: none;
	}

	.content-outer-wrap-2 {
		padding-right: 0rem;
		padding-bottom: 2rem;
		padding-left: 0rem;
	}

	.image-21 {
		height: 200px;
	}

	.container-2.inside-page-container {
		max-width: calc(1200px + 2rem);
		padding: 0px 1rem;
	}
}

@font-face {
	font-family: 'Homestead';
	src: url('../fonts/Homestead-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

/* Phone links */
.phone-mobile {
	display: none;
}

@media (max-width: 991px) {
	.phone-mobile {
		display: inline-block;
	}

	.phone-desktop {
		display: none;
	}
}

/* IE 11 fixes */
@media screen and (-ms-high-contrast: active),
	(-ms-high-contrast: none) {

	.mm-wrapper_opened .navbar-2,
	.mm-wrapper_opened .navbar {
		position: absolute;
	}
}

/* Redirect modal */
.redirect-modal {
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.4);
	justify-content: center;
	align-items: center;
	display: flex;
	z-index: 9999;
}

.redirect-modal.hide {
	display: none;
}

.redirect-modal-content {
	text-align: center;
	background-color: #fff;
	width: 500px;
	height: 490px;
	border: 35px solid transparent;
	border-image-source: url("/20200110134700/assets/images/border.png");
	border-image-slice: 10%;
	border-image-width: 60px;
	border-image-outset: 60px;
	border-image-repeat: round;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-family: Open Sans, sans-serif;
	position: relative;
}

.redirect-modal-content h1 {
	font-weight: 800;
	font-size: 2.8rem;
	line-height: 1;
	text-transform: uppercase;
}

.redirect-modal-content p {
	color: #bf3028;
	font-size: 1.6rem;
	line-height: 1.2;
}

.close-icon {
	position: absolute;
	right: -110px;
	top: -110px;
	background-image: url("/20200110134700/assets/images/green-leaf-x.png");
	width: 100px;
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-repeat: no-repeat;
}

.close-icon a {
	color: #fff;
	font-size: 1.5rem;
	text-decoration: none;
}

@media (max-width: 991px) {
	.redirect-modal-content {
		width: 400px;
		height: 392px;
		border-width: 35px;
		border-image-slice: 8%;
		border-image-width: 40px;
		border-image-outset: 40px;
	}

	.redirect-modal-content h1 {
		font-size: 2.2rem;
		margin-top: 0;
	}

	.redirect-modal-content p {
		font-size: 1.3rem;
	}

	.close-icon {
		width: 70px;
		background-size: 70px;
		height: 70px;
		right: -80px;
		top: -80px;
	}

	.close-icon a {
		margin-right: 10px;
		margin-top: -5px;
	}

	.redirect-modal-content .buy-now {
		padding: 10px 25px;
		margin-top: 5px;
	}
}

@media (max-width: 767px) {
	.redirect-modal-content {
		width: 350px;
		height: 343px;
		border-width: 10px;
		border-image-slice: 10%;
		border-image-width: 35px;
		border-image-outset: 35px;
		margin-left: -15px;
	}

	.redirect-modal-content h1 {
		font-size: 2rem;
	}

	.redirect-modal-content p {
		font-size: 1.2rem;
	}

	.close-icon {
		right: -55px;
		top: -55px;
	}
}

@media (max-width: 479px) {
	.redirect-modal-content {
		width: calc( 100% - 110px );
		height: 100vw;
		border-width: 1px;
		border-image-slice: 16%;
		border-image-width: 45px;
		border-image-outset: 45px;
	}

	.redirect-modal-content h1 {
		font-size: 8vw;
		margin-left: -15px;
		margin-right: -15px;
	}

	.redirect-modal-content p {
		font-size: max( 5vw, 17px );
		margin-left: -15px;
		margin-right: -15px;
	}

	.close-icon {
		width: 60px;
		background-size: 60px;
		height: 60px;
		right: -50px;
		top: -50px;
	}

	.close-icon a {
		margin-right: 6px;
		margin-top: -7px;
		font-size: 1.2rem;
	}

	.redirect-modal-content .buy-now {
		padding: 8px 16px;
		margin-top: 5px;
	}
}


/********************* eCommerce *********************/
/*****************************************************/
#ecommerceLinks {
	position: fixed;
	bottom: 0;
	z-index: 10000;
	top: 11px;
	right: 258px;
	background: #fff;
	height: 38px;
	padding: 8px 0px;
}

#ecommerceLinks a {
	padding: 13px;
	text-decoration: none;
}

#ecommerceLinks a.account-icon {
	padding-right: 6px;
}

.ecommerceLinkText {
	display: inline;
	font-weight: 700;
	font-size: 0.75rem;
	text-align: right;
	text-transform: uppercase;
	text-decoration: none;
	line-height: 0.75rem;
	color: #bf3028;
	margin-top: 3px;
	margin-left: 1px;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
}

.ecommerceLinkImg {
	width: 18px;
	height: 18px;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.ecommerceLinkImg:hover {
	-webkit-transform: scale(1.1) !important;
	-ms-transform: scale(1.1) !important;
	transform: scale(1.1) !important;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
}

/* Locations */

#locations_module.list .location_types_title {
	padding-bottom: 5px;
	margin: -10px 0px 15px 0px;
	border-bottom: 1px solid #000000;
}

#locations_module_search_contentbox.contentbox_item .item_label {
	display: block;
	margin-bottom: 5px;
}

#locations_module_display_view_contentbox.contentbox_item span {
	margin: 5px 10px;
	width: auto;
	height: 24px;
	display: inline-block;
}

#locations_module.list .metadata2.address {
	color: #333;
	font-size: 17px;
	line-height: 22px;
	text-align: left;
	margin-bottom: 5px;
}

#locations_module_search_contentbox.contentbox_item .item_field input#PostalCode {
	width: calc(50% - 10px);
	padding: 8px 12px;
}
